/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { decode } from 'he';
import getAbsoluteUrl from '../utils/get-absolute-url';

function processYoastTitle(siteTitle, titleString, pageTitle, sep = '|') {
  if (titleString) {
    const mapObj = {
      '%%title%%': pageTitle,
      '%%sep%%': sep,
      '%%sitename%%': siteTitle,
    };
    const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

    const newTitle = titleString.replace(re, matched => {
      return mapObj[matched];
    });

    return decode(newTitle, {
      isAttributeValue: true,
    });
  }

  return decode(`${pageTitle} | ${siteTitle}`, {
    isAttributeValue: true,
  });
}

function getResizedImageSrc(imageObj) {
  if (imageObj && imageObj.childImageSharp.resize.src) {
    return imageObj.childImageSharp.resize.src;
  }

  return null;
}

function getImageWithFallback(imageObj, defaultImageObj) {
  const defaultImageSrc = getResizedImageSrc(defaultImageObj.localFile);
  const imageSrc = getResizedImageSrc(imageObj);

  if (imageSrc) {
    // console.info('override default image');
    return imageSrc;
  }

  if (defaultImageSrc) {
    // console.info('default image');
    return defaultImageSrc;
  }

  return null;
}

function Seo({
  defaultDescription,
  description,
  lang,
  meta,
  keywords,
  title,
  fallbackTitle,
  ogImage,
  twitterImage,
  noRobots,
}) {
  const { site, wordpressAcfOptions } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        wordpressAcfOptions {
          options {
            defaultOgImage: default_sharing_image {
              localFile {
                childImageSharp {
                  resize(width: 1200, height: 630) {
                    src
                  }
                }
              }
            }
            defaultTwitterImage: default_sharing_image_twitter {
              localFile {
                childImageSharp {
                  resize(width: 876, height: 438) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const metaDescription = description && description.length > 0 ? description : defaultDescription;
  const processedTitle = processYoastTitle(site.siteMetadata.title, title, fallbackTitle);
  const openGraphImageSrc = getAbsoluteUrl(getImageWithFallback(ogImage, wordpressAcfOptions.options.defaultOgImage));
  const twitterImageSrc = getAbsoluteUrl(
    getImageWithFallback(twitterImage, wordpressAcfOptions.options.defaultTwitterImage)
  );

  let optionalMeta = [];

  if (noRobots) {
    optionalMeta = [
      ...optionalMeta,
      {
        name: 'robots',
        content: 'noindex,nofollow',
      },
    ];
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={site.siteMetadata.title}
      title={processedTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: processedTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: openGraphImageSrc,
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '630',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: '@liminal_pages',
        },
        {
          name: 'twitter:title',
          content: processedTitle,
        },
        {
          name: 'twitter:creator',
          content: '@liminal_pages',
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: twitterImageSrc,
        },
        ...optionalMeta,
      ]
        .concat(
          keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: '',
  defaultDescription:
    'Book editing for authors (manuscript critique, developmental editing, line editing, copy-editing). Speculative fiction specialist (fantasy, sci-fi, etc.)',
  noRobots: false,
};

Seo.propTypes = {
  description: PropTypes.string,
  defaultDescription: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  noRobots: PropTypes.bool,
};

export default Seo;
